/* src/components/CartPage.css */

/* Styling for the bottom overlay */
.bottom-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95); /* Semi-transparent background */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

/* Styling for the back button and payment button */
.back-button,
.payment-button {
  padding: 10px 20px;
}

.back-button {
  border-radius: 0;
}

.payment-button {
  border-radius: 0;
  background-color: rgba(255,196,0,255);
  border-color: rgba(255,196,0,255);
}

.clearcartbtn{
  border-radius: 0;
}
.cart-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full height of the viewport */
}

.cart-content {
  flex: 1; /* Take up available space */
}

.bottom-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9); /* Slightly transparent background */
  padding: 10px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it's on top */
}

/* Remove any default margin/padding from the ContactCard */
.contact-card {
  margin-bottom: 78px;
  padding: 2;
}

.cartIcon{
  width: 27px;  /* Set the desired width */
  height: 27px; /* Set the desired height */
  margin-right:2px;
  vertical-align: text-bottom;
  
}

.titleHeads{
  font-size:20px;
border-radius: 9px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
color: #ffbf00;
}

font-face {
  font-family: Poppins;
  src: url('./Poppins-Medium.ttf');
}
.Poppins{
  font-family: Poppins;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.overlay-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.qr-code {
  width: 150px;
  height: auto;
  margin-bottom: 20px;
}

.upi-section,
.token-section {
  margin: 15px 0;
}

.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Dimmed background */
  backdrop-filter: blur(5px); /* Optional: Adds blur effect */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.payment-modal {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.qr-placeholder {
  width: 150px;
  height: 150px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ccc; /* Optional border for styling */
  overflow: hidden; /* Ensure image doesn't overflow */
  background-color: #fff; /* White background for better visibility */
}

.qr-placeholder img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Ensures the QR code scales proportionally */
}


